import { Controller } from "stimulus"

export default class extends Controller {

  connect(){
    let options = {}
    let dataMap = this.data
    const options_keys =["animation", "container", "delay", "html", "placement", "selector", "template", "title", "trigger", "offset", "fallbackPlacement", "boundary", "sanitize", "whiteList", "sanitizeFn", "popperConfig"]
    //for (key in options_keys) {
    //  if (this.data.has($key)) options[$key] = this.data.get($key)
    //}
    options_keys.forEach(function(key){
      if (dataMap.has(key)) options[key] = dataMap.get(key)
    })
    $(this.element).tooltip(options)
    //this.element.setAttribute("data-reflex-permanent", true)
  }



}
