import { Controller } from "stimulus";

export default class extends Controller {

  connect() {
    const modalOptions = [];
    const modalElement = $(this.element)
    modalOptions.autoShow = this.data.get("init")
    if (modalOptions.autoShow && modalOptions.autoShow != null && modalOptions.autoShow != "false" ) {
      modalElement.modal()
      modalElement.modal('show')
    }
  }

  submitForm(e){
    e.preventDefault()

    let formId = e.target.dataset.submitFormId

    let action = e.target.dataset.submitFormAction

    if($("#" + formId).length){

      if(action != ""){
        $("#" + formId).prepend('<input name="' + action + '" type="hidden" />')
      }

      $("#" + formId).submit()
    }
  }

}
