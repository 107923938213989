import ApplicationController from './application_controller'

export default class extends ApplicationController {

  static targets = ["item"]

  connect() {
    super.connect()
    this.sortTargets();
    
    this.updateRefreshButton()
  }

  disconnect() {
    super.disconnect()
    clearInterval(this.refreshTimer)
  }

  updateRefreshButton() {
    this.refreshTimer = setInterval(() => {
      console.log(this.element.dataset.mailOrdersCount)
      this.stimulate('MailOrders#update_refresh_button', this.element, { "displayed": parseInt(this.element.dataset.mailOrdersCount) })
    }, 60000)
  }

  // https://medium.com/pragmatic-programmers/observing-and-responding-to-dom-changes-7aa146ec94bd
  initialize() {
    const target = this.element;
    const observer = new MutationObserver(mutations => {
      observer.disconnect();
      Promise.resolve().then(start);
      this.sortTargets();
    });

    const start = () => {
      observer.observe(target, { childList: true, subtree: true });
    };
    start();
  }

  beforeOrder(element, reflex, noop, reflexId) {
    this.element.dataset.mailOrdersSortedBy = element.dataset.columnName
    element.dataset.direction = element.dataset.direction == "asc" ? "desc" : "asc"
    this.element.dataset.mailOrdersSortedDirection = element.dataset.direction
    this.element.reflexData[reflexId].dataset["data-direction"] = this.element.dataset.mailOrdersSortedDirection

    element.classList.add("selected")

    const otherElements = this.element.querySelectorAll("th")
    otherElements.forEach(e => {
      if (e != element) {
        e.classList.remove("selected")
      }
    })

    const icon = element.querySelector("i")
    icon.innerText = element.dataset.direction == "asc" ? "arrow_upward" : "arrow_downward"
  }

  afterOrder(el) {
    this.sortTargets();
  }

  sortTargets() {
    const sortDirection = this.element.dataset.mailOrdersSortedDirection === 'desc' ? -1 : 1;
    const tbody = this.element.querySelector('tbody');
    
    this.itemTargets
      .sort((a, b) => {
        const sortValueA = this.sortValue(a);
        const sortValueB = this.sortValue(b);
        if (sortValueA < sortValueB) {
          return -1 * sortDirection;
        }
        if (sortValueA > sortValueB) {
          return 1 * sortDirection;
        }
        return 0;
      })
      .forEach(element => tbody.append(element));
  }

  sortValue(element) {
    if (this.element.dataset.mailOrdersSortedBy == "") { 
      return 0;
    }

    const firstChild = element.querySelector(`[data-mail-orders-column="${this.element.dataset.mailOrdersSortedBy}"]`);

    if (this.element.dataset.mailOrdersSortedBy == "received_at" || this.element.dataset.mailOrdersSortedBy == "level1_check") {
      return parseInt(firstChild.dataset.mailOrdersColumnValue, 10);
    } else {
      return firstChild.innerText;
    }
  }
}
