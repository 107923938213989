import { Controller } from 'stimulus'
import StimulusReflex from 'stimulus_reflex'


/* This is your application's ApplicationController.
 * All StimulusReflex controllers should inherit from this class.
 *
 * Example:
 *
 *   import ApplicationController from './application_controller'
 *
 *   export default class extends ApplicationController { ... }
 *
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends Controller {
  connect () {
    if (process.env.RAILS_ENV === 'development') StimulusReflex.debug = true
    StimulusReflex.register(this)
  }

  /* Application wide lifecycle methods.
   * Use these methods to handle lifecycle concerns for the entire application.
   * Using the lifecycle is optional, so feel free to delete these stubs if you don't need them.
   *
   * Arguments:
   *
   *   element - the element that triggered the reflex
   *             may be different than the Stimulus controller's this.element
   *
   *   reflex - the name of the reflex e.g. "ExampleReflex#demo"
   *
   *   error - error message from the server
   */

  beforeReflex (element, reflex, noop, reflexId) {
    // adapté depuis https://github.com/stimulusreflex/stimulus_reflex/issues/443
    this.element.reflexData[reflexId].dataset["data-tab-id"] = document.body.dataset.tabId

    document.querySelectorAll('.tooltip.show').forEach(e => e.remove())
  }

  reflexSuccess (element, reflex, noop, reflexId) {
  }

  reflexError (element, reflex, noop, reflexId) {
    if (noop.startsWith('invalid user')) {
      location.reload()
    }
  }

  afterReflex (element, reflex, noop, reflexId) {
    // suppression des tooltips affichés
    document.querySelectorAll('.tooltip.show').forEach(e => e.remove())

    // fermer les dropdown ouverts
    document.querySelectorAll('.dropdown.show, .dropdown-menu.show').forEach(e => e.classList.remove('show'))

    // à la mise à jour, faire un focus sur les champs autofocus
    const focusElement = document.querySelector('[autofocus]')
    
    if (window.scrollY == 0 && focusElement) {
      console.log("autofocus")

      focusElement.focus()

      // shenanigans to ensure that the cursor is placed at the end of the existing value
      const value = focusElement.value
      focusElement.value = ''
      focusElement.value = value
    }
  }

  finalizeReflex (element, reflex, noop, reflexId) {
  }

  _refresh_lists(element, reflex, noop, reflexId) {
    if (!!document.getElementById('account-tasks-list')) {
      this.element.reflexData[reflexId].dataset["data-refresh-account-tasks-list"] = true
      this.element.reflexData[reflexId].dataset["data-refresh-account-tasks-list-for-account-id"] = document.getElementById('show-account').dataset.accountId
    }

    if (!!document.getElementById('account-activities-list')) {
      this.element.reflexData[reflexId].dataset["data-refresh-account-activities-list"] = true
      this.element.reflexData[reflexId].dataset["data-refresh-account-activities-list-for-account-id"] = document.getElementById('show-account').dataset.accountId
    }

    if (!!document.getElementById('account-mail-orders-list')) {
      this.element.reflexData[reflexId].dataset["data-refresh-account-mail-orders-list"] = true
      this.element.reflexData[reflexId].dataset["data-refresh-account-mail-orders-list-for-account-id"] = document.getElementById('show-account').dataset.accountId
    }

    if (!!document.getElementById('dashboard-tasks-list')) {
      this.element.reflexData[reflexId].dataset["data-refresh-dashboard-tasks-list"] = true
    }

    if (!!document.getElementById('dashboard-activities-list')) {
      this.element.reflexData[reflexId].dataset["data-refresh-dashboard-activities-list"] = true
    }

    if (!!document.getElementById('dashboard-mail-orders-list')) {
      this.element.reflexData[reflexId].dataset["data-refresh-dashboard-mail-orders-list"] = true
    }
  }
}
