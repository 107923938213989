import ApplicationController from './application_controller'

export default class extends ApplicationController {

  connect() {
  }

  close() {
    $(this.element).removeClass("show").html("")
    document.getElementById('allWrapper').setAttribute('data-side-panel-sgid', '')
  }

  autoShow() {
    if ($(this.element).html().length > 1 ) {
      $(this.element).addClass("show")
    }
  }

}
