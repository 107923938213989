import { Controller } from "stimulus"

export default class extends Controller {
  //static targets = [ "output" ]

  connect() {
    const type = this.data.has("type") ? this.data.get("type") : "bar"
    const data = JSON.parse(this.data.has("data") ? this.data.get("data") : "")
    const options = JSON.parse(this.data.has("options") ? this.data.get("options") : "")


    const chart = new Chart(this.element.getContext("2d"), {
      type,
      data: data,
      options: options
    });

  }
}
