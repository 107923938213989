import ApplicationController from './application_controller'
import bsBreakpoints from 'bs-breakpoints'
import Cookies from 'js-cookie'

export default class extends ApplicationController {

  connect(){
    if ($("body").hasClass("navdrawer-init")) {
      this.initNavDrawer()
    }
  }

  initNavDrawer(){
    let bsBreakpoint = bsBreakpoints.detectBreakpoint()
    if ((bsBreakpoint == "xLarge" || bsBreakpoint == "large") && Cookies.get("navdrawerOpen") != "false") {
      $("body").addClass("navdrawer-open-persistent-lg")
      $("#navDrawer").navdrawer("show")
      // Retirer la limitation de focus pour les element hors drawer
      $(document).off('focusin.md.navdrawer')
    } else {
      $("#navDrawer").removeClass("show")
      $("#navDrawer").navdrawer("hide")
    }
    $("body").removeClass("navdrawer-init")
  }

  showNavDrawer() {
    $("#navDrawer").navdrawer("show")
    Cookies.set('navdrawerOpen', true)
    // Retirer la limitation de focus pour les element hors drawer
    $(document).off('focusin.md.navdrawer')
    this.setNavDrawertoggler()
  }

  hideNavDrawer() {
    Cookies.set('navdrawerOpen', false)
    $("#navDrawer").navdrawer('hide')
  }

  setNavDrawertoggler() {
    let ctrl = this
    let backdrop = document.getElementsByClassName('navdrawer-backdrop-persistent-lg').item(0)
    if (backdrop) {
      backdrop.addEventListener("click", function(e){
        ctrl.hideNavDrawer()
      })
    }
  }


  /* Reflex specific lifecycle methods.
   * Use methods similar to this example to handle lifecycle concerns for a specific Reflex method.
   * Using the lifecycle is optional, so feel free to delete these stubs if you don't need them.
   *
   * Example:
   *
   *   <a href="#" data-reflex="ExampleReflex#example">Example</a>
   *
   * Arguments:
   *
   *   element - the element that triggered the reflex
   *             may be different than the Stimulus controller's this.element
   *
   *   reflex - the name of the reflex e.g. "ExampleReflex#example"
   *
   *   error - error message from the server
   */

  // beforeUpdate(element, reflex) {
  //  element.innerText = 'Updating...'
  // }

  // updateSuccess(element, reflex) {
  //   element.innerText = 'Updated Successfully.'
  // }

  // updateError(element, reflex, error) {
  //   console.error('updateError', error);
  //   element.innerText = 'Update Failed!'
  // }
}
