import ApplicationController from './application_controller'

export default class extends ApplicationController {

  beforeSubmit(el) {
    this._disableForm(el)
  }

  afterSubmit(el) {
    this._enableForm(el)
  }


  afterEdit(el) {
  //  $(".side-panel > div, .side-panel > form").addClass("boum")
  }

  beforeCreate(element, reflex, noop, reflexId) {
    super._refresh_lists(element, reflex, noop, reflexId)
  }

  beforeUpdate(element, reflex, noop, reflexId) {
    super._refresh_lists(element, reflex, noop, reflexId)
  }

  _disableForm(el) {
    var btn = $(el).find('.btn')
    btn.attr("disabled", true)
    btn.addClass("btn-wait")
  }

  _enableForm(el) {
    var btn = $(el).find('.btn')
    btn.attr("disabled", false)
    btn.removeClass("btn-wait")
  }


}
