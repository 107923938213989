import { Controller } from "stimulus";

export default class extends Controller {

  connect() {
    let ctrl = this

    if (!this.data.has("autoshow") || this.data.get("autoshow") != "false") {
      $(this.element).on('hidden.bs.modal', function () {
        $(ctrl.element).remove()
      });

      this.show()
    }
  }

  disconnect() {
    this.hide()
  }

  show() {
    $(this.element).modal({
      backdrop: 'static',
      keyboard: false
    })
  }

  hide() {
    $(this.element).modal('hide')
  }

  submitForm(e) {
    e.preventDefault()

    if (this.data.has("formIdToSubmit")) {
      $("#" + this.data.get("formIdToSubmit")).submit()
    }
  }

}
