import { camelize } from 'inflected'

import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['query']

  connect () {
    super.connect()
    this.perform = this._debounce(this._perform, 500).bind(this)

    window.onpopstate = function(event) {
      document.location.reload();
    }
  }

  beforePerform (element, reflex) {

  }

  _perform (event) {

    event.preventDefault()
    this.stimulate('MailOrderArchives#search', this.queryTarget)
  }

  // https://gist.github.com/nmsdvid/8807205
  _debounce (func, wait, immediate) {
    let timeout;
    return function() {
      let context = this, args = arguments;
      clearTimeout(timeout);
      timeout = setTimeout(function() {
        timeout = null;
        if (!immediate) func.apply(context, args);
      }, wait);
      if (immediate && !timeout) func.apply(context, args);
    };
  }

  // http://expo.stimulusreflex.com/demos/permalink
  filter (e) {
    this.stimulate('MailOrderArchives#filter', e.target)
  }

  beforeReflex (element) {
    let elementName = element.name

    if (elementName == undefined){
      return
    }

    if (elementName.indexOf('[') >= 0) {
      elementName = elementName.match(/[^[\]]+(?=])/g)[0]
    }

    elementName = camelize(elementName, false)

    if (element.type === 'checkbox') {
      this.data.set(
        elementName,
        Array.from(document.querySelectorAll(`input[name=${element.name}]`))
          .filter(e => e.checked)
          .map(e => e.value)
          .join(',')
      )
    } else {
      this.data.set(elementName, element.value)
    }

    $('#loading').removeClass('d-none')
    $('#loading').addClass('d-flex')
  }

  afterReflex (element, reflex, error) {

    if (!error) {
      const camelizedIdentifier = camelize(this.identifier.replace(/-/g, '_'), false)
      const params = new URLSearchParams(window.location.search.slice(1))
      params.delete('reset_filters')
      Object.keys(Object.assign({}, this.element.dataset))
        .filter(attr => attr.startsWith(camelizedIdentifier))
        .forEach(attr => {
          const paramName = attr.slice(camelizedIdentifier.length).split(/(?=[A-Z])/).join('_').toLowerCase();
          let dataName = attr.slice(camelizedIdentifier.length)
          dataName = dataName.charAt(0).toLowerCase() + dataName.slice(1)
          const paramValue = this.data.get(dataName)
          paramValue != null && paramValue.length
            ? params.set(paramName, paramValue)
            : params.delete(paramName)
        })
      const qs = params
        .toString()
        .replace(/%28/g, '(')
        .replace(/%29/g, ')')
        .replace(/%2C/g, ',')
      const query = qs.length ? '?' : ''
      history.pushState({}, '', `${window.location.pathname}${query}${qs}`)
    }

    $('#loading').removeClass('d-flex')
    $('#loading').addClass('d-none')
  }

  beforeFilter(element, reflex, noop, reflexId) {
    $('#loading').removeClass('d-none')
    $('#loading').addClass('d-flex')
  }

  afterFilter(element, reflex, noop, reflexId) {
    $('#loading').removeClass('d-flex')
    $('#loading').addClass('d-none')
  }
}
