import { Controller } from "stimulus"

export default class extends Controller {
  //static targets = [ "output" ]

  connect() {
    const barColor = this.data.has("barColor") ? this.data.get("barColor") : 10
    const barBgColor = this.data.has("barBgColor") ? this.data.get("barBgColor") : "#eeeeee"
    const barWidth = this.data.has("barWidth") ? this.data.get("barWidth") : 10
    const radius = this.data.has("radius") ? this.data.get("radius") : 35
    const initValue = this.data.has("initValue") ? this.data.get("initValue") : 0
    const value = this.data.has("value") ? this.data.get("value") : 0
    const displayedValue = this.data.has("displayedValue") ? this.data.get("displayedValue") : value
    const frameTime = this.data.has("frameTime") ? this.data.get("frameTime") : 1
    const radial1 = radialIndicator(this.element, {
      barColor: barColor,
      barBgColor: barBgColor,
      barWidth : barWidth,
      radius : radius,
      initValue: initValue,
      format: function(value){return displayedValue},
      frameTime: frameTime
    });
    radial1.animate(value)
  }
}
