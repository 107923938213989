import ApplicationController from './application_controller'

export default class extends ApplicationController {
  afterEdit(el) {
  //  $(".side-panel > div, .side-panel > form").addClass("boum")
  }

  beforeSubmit(el) {
    this._disableForm(el)
  }

  afterSubmit(el) {
    this._enableForm(el)
  }

  destroy(el) {
    // This is where we are prompting the user for confirmation
    const ok = confirm("Are you sure you want to DELETE this mail order ?")
    if(!ok){
      console.log("Stop")
      return false;
    }else{
      console.log("Go")
      this.stimulate('MailOrder#destroy', el.currentTarget)
    }
  }

  beforeDestroy(element, reflex, noop, reflexId) {
    super._refresh_lists(element, reflex, noop, reflexId)
  }

  afterDestroy(el) {
    console.log("AfterDestroy ?")
  }

  beforeToggleCheck(element, reflex, noop, reflexId) {
    super._refresh_lists(element, reflex, noop, reflexId)
    // this._disableFabBtn()
    if ($(element).hasClass("custom-control")) {
      $(element).addClass("custom-control-wait")
    }
  }

  afterToggleCheck(el) {
    if ($(el).hasClass("custom-control")) {
      $(el).removeClass("custom-control-wait")
    }
  }

  afterShow(el) {
    $(el).closest('tr').addClass('mail-order-selected')
    $(el).closest('tr').siblings().removeClass('mail-order-selected')
  }

  beforeUpdate(element, reflex, noop, reflexId) {
    super._refresh_lists(element, reflex, noop, reflexId)
  }

  _disableForm(el) {
    var btn = $(el).find('.btn')
    btn.attr("disabled", true)
    btn.addClass("btn-wait")
  }

  _enableForm(el) {
    var btn = $(el).find('.btn')
    btn.attr("disabled", false)
    btn.removeClass("btn-wait")
  }

}
