import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['chip']

  connect() {
  }

  change(e) {
    let chipInput = $(e.currentTarget).find('input').first().get(0)

    chipInput.checked = !chipInput.checked
    $(e.currentTarget).toggleClass("selected", chipInput.checked)

    if (this.data.get('allowNone') == "false" || chipInput.checked) {
      let toCheck = $(this.element).parents(".chips-container").find(".chip-checkbox").not(e.currentTarget).first()

      if (toCheck) {
        toCheck.attr('checked', !chipInput.checked)
        toCheck.toggleClass("selected", !chipInput.checked)
      }
    }

    let event = new Event('change', { bubbles: true })
    chipInput.dispatchEvent(event)
  }
}
