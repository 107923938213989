import { Controller } from "stimulus"

export default class extends Controller {

  disconnect(){

    if(this.debug){
      console.log("disconnect")
    }

    clearInterval(this.checkActiveInterval)
    clearInterval(this.timerInterval)
  }

  connect(){

    this.debug = false

    if(this.debug){
      console.log("connect")
    }

    this.showModalBefore = 300 // seconds

    this.timerStarted = false
    this.timerInterval = null
    this.checkActiveInterval = null

    let ctrl = this

    this.checkActiveInterval = setInterval(function () {

      if(ctrl.debug){
        console.log("check active")
      }

      let url = ctrl.data.get("endpoint")

      let modal = $('#idleModal')

      $.ajax({
        type: "GET",
        url: url,
        success: function(data){
          if(ctrl.debug){
            console.log("data : " + data + " showModalBefore : " + ctrl.showModalBefore)
          }

          if(data >= 0 && data <= ctrl.showModalBefore && ctrl.timerStarted == false){
            ctrl._startTimer(data)
          }else if(data > ctrl.showModalBefore){
            ctrl.keepActive()
          }
        }
      });
    }.bind(ctrl), this.data.get("frequency") * 1000)
  }

  keepActive(){
    if(this.debug){
      console.log("Keep Active")
    }

    $("#idleModal").modal("hide")

    if(this.timerInterval){
      clearInterval(this.timerInterval)
      this.timerInterval = null
      this.timerStarted = false
    }
  }

  _startTimer(duration){

    if(this.timeStarted){
      return
    }

    if(this.debug){
      console.log("Start Timer")
    }

    this.timerStarted = true

    let ctrl = this

    let timer = duration, minutes, seconds;

    this.timerInterval = setInterval(function () {
      minutes = parseInt(timer / 60, 10);
      seconds = parseInt(timer % 60, 10);

      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;

      $('[data-target="idle.seconds"]').html('<strong>' + minutes + ":" + seconds + '</strong>')

      if(!$('#idleModal').hasClass('show')){
        $("#idleModal").modal("show")
      }

      if (--timer <= 0) {
        window.location.href = ctrl.data.get("timeout")
      }
    }, 1000);

  }

}
