import { Controller } from "stimulus"
import Inputmask from "inputmask";

export default class extends Controller {

  connect(e) {

    let picker = $(this.element)
    picker.pickdate({

      //TODO :
        // Permettre es date future par défaut
        // Avoir le bon format de date

      //"monthsFull": I18n.t("date.month_names").map(string => string.toLowerCase().replace(/^\w/, c => c.toUpperCase())),
      //"monthsShort": I18n.t("date.abbr_month_names").map(string => string.toLowerCase().replace(/^\w/, c => c.toUpperCase())),
      //"weekdaysShort": I18n.t("date.abbr_day_names").map(string => string.substring(0,2).replace(/^\w/, c => c.toUpperCase())),
      //"weekdaysFull": I18n.t("date.abbr_day_names").map(string => string.toLowerCase().replace(/^\w/, c => c.toUpperCase())),
      "selectYears": 1000,
      "selectMonths": true,
      //"ok": I18n.t("btn.labels.date_picker_ok"),
      //"cancel": I18n.t("btn.labels.cancel"),
      "firstDay": 1,
      "format": "dd/mm/yyyy",
      "container": "body",
      "closeOnSelect": true,
      "min": new Date(1990,0,1),
      //"max": (this.data.get("allowFuture") && this.data.get("allowFuture") != "true")
    })
    // Disable Opening of Date Picker on click or focus
    picker.off("focus")
    picker.off("click")
    // Disable read only on Date Picker field
    picker.removeAttr("readonly")
    let calendarIcon = picker.next(".picker-opener")
    if (calendarIcon.length < 1) {
      calendarIcon = $("<i class=\"material-icons picker-opener\">today</i>")
      picker.after(calendarIcon)
    }
    calendarIcon.on("click", function(e){
      picker.pickadate('picker').open()
      e.stopPropagation()
    })
    // Focus sur le champs du picker après sa  fermeture

    picker.pickadate('picker').on({
      close: function() {
        picker.focus()
      }
    })

    // Apply Input Mask to Date Picker

    let im = Inputmask("99/99/9999", { "placeholder": "––/––/––––" })
    picker.attr("placeholder", "––/––/––––")
    im.mask(this.element)

    // Contraindre l'année
    let limiterIdOrName = this.data.get("limitYearBy")

    let ctrl = this

    if (limiterIdOrName) {

      $('body').on("change", limiterIdOrName, function(e) {
        if ($(limiterIdOrName).attr("type") == "radio") {
          ctrl._limitYear($(limiterIdOrName + ":checked").val())
        } else if ($(limiterIdOrName).val() != "") {
          ctrl._limitYear($(limiterIdOrName).val())
        }
      });

      $(limiterIdOrName).trigger("change")
    }

  }

  _limitYear(year) {
    $(this.element).pickadate('picker').set('min', [year,0,1]).set('max', [year,11,31])
  }

}
